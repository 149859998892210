import { Form, InputNumber, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  materialCrudApi,
  otherExtendedApi,
  recipeCrudApi,
  recipeCrudExtendedApi,
} from "../client/EntityApi";
import {
  getRecipeCPColumns,
  getRecipeCPFormItems,
} from "../components/crud/RecipeCPComponents";
import RecipeExpandedTable from "../components/crud/RecipeExpandedTable";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { Rules } from "../core/Rules";
import ProductionIcon from "../Icons/ProductionIcon";

const RecipeCrudPage = () => {
  const [produceForm] = Form.useForm();
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [produceModalIsOpen, setProduceModalIsOpen] = useState(false);
  const [produceLoading, setProduceLoading] = useState(false);
  const [mappedMaterials, setMappedMaterials] = useState([]);
  const [mappedRecipes, setMappedRecipes] = useState<any[]>([]);
  const [currencyRates, setCurrencyRates] = useState({
    dolar: 1,
    euro: 1,
    tl: 1,
  });

  useEffect(() => {
    materialCrudApi.getAll().then((response) => {
      const mappedMaterials = response["hydra:member"].map((material: any) => {
        return { label: material.name, value: material["@id"] };
      });
      setMappedMaterials(mappedMaterials);
    });

    updateCurrentRates();
    const currencyInterval = setInterval(updateCurrentRates, 15000);

    return () => clearInterval(currencyInterval);
  }, []);

  const updateCurrentRates = () => {
    otherExtendedApi.getCurrencies().then((response) => {
      setCurrencyRates({ dolar: response.dolar, euro: response.euro, tl: 1 });
    });
  };

  const onCancel = () => {
    produceForm.resetFields();
    setProduceModalIsOpen(false);
    setProduceLoading(false);
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel="Reçete"
        api={recipeCrudApi}
        searchKeys={["name", "code"]}
        columns={getRecipeCPColumns(currencyRates)}
        addFormItems={getRecipeCPFormItems(mappedMaterials)}
        editFormItems={getRecipeCPFormItems(mappedMaterials)}
        deleteModalMessage="Bu reçeteyi sildiğinizde bu reçetenin üretim geçmişide silinecektir. Silmek istiyor musunuz ?"
        expandable={{
          expandedRowRender: (record) => (
            <RecipeExpandedTable recipe={record} />
          ),
        }}
        extraTitleProcess={() => {
          return (
            <CrudPageProcessButton
              tooltipText="Üretim Yap"
              icon={<ProductionIcon />}
              onClick={() => setProduceModalIsOpen(true)}
            />
          );
        }}
        afterRefreshOperation={(data) => {
          const mappedRecipes = data.map((recipe) => {
            return {
              label: recipe.name,
              key: recipe.name,
              value: recipe.id,
            };
          });
          setMappedRecipes(mappedRecipes);
        }}
        setEditFields={(row) => {
          const mappedRecipeMaterials = row.recipeMaterials
            .filter((recipeMaterial: any) => recipeMaterial.material !== null)
            .map((recipeMaterial: any) => {
              return {
                material: recipeMaterial.material["@id"],
                quantity: recipeMaterial.quantity,
              };
            });

          return {
            name: row.name,
            code: row.code,
            recipeMaterials: mappedRecipeMaterials,
          };
        }}
      />
      <Modal
        maskClosable={false}
        forceRender
        visible={produceModalIsOpen}
        title={"Üretim Yap"}
        onOk={() => {
          produceForm.validateFields().then((values) => {
            setProduceLoading(true);
            recipeCrudExtendedApi
              .generateRecipe(values)
              .then((response) => {
                crudPageRef.current?.refreshData();
                toast.success(response.message);
              })
              .finally(() => onCancel());
          });
        }}
        onCancel={onCancel}
        okText="Üret"
        cancelText="Vazgeç"
        confirmLoading={produceLoading}
      >
        <Form form={produceForm} autoComplete="off" layout="vertical">
          <Form.Item
            label="Üretilecek Reçete"
            name="recipeId"
            rules={[Rules.required]}
          >
            <Select
              showSearch
              options={mappedRecipes}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            label="Üretim Miktarı"
            name="quantity"
            initialValue={0}
            rules={[Rules.required]}
          >
            <InputNumber min={0} precision={0} />
          </Form.Item>
          <Form.Item
            label="Üretim Açıklaması"
            name="description"
            rules={[Rules.max(2000)]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RecipeCrudPage;
