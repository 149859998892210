import { cellStyle, mathRound } from "../core/helper/UtilHelper";

const FeedScreenTable = (props: any) => {
  return (
    <table
      style={{
        width: "100%",
        textAlign: "center",
        borderCollapse: "collapse",
        border: "1px solid #dddddd",
        margin: "1em 0px 1em 0px",
      }}
    >
      <thead>
        <tr style={cellStyle}>
          <th colSpan={2} style={cellStyle}>
            Yem Elek Analizi
          </th>
          <th colSpan={2} style={cellStyle}>
            Padok İsmi : {props.baitTable.padokName}
          </th>
          <th colSpan={1} style={cellStyle}>
            Numune (gr) : {props.baitTable.sample}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={cellStyle}>
          <th style={cellStyle}>Elek İsmi</th>
          <th style={cellStyle}>Ref (%)</th>
          <th style={cellStyle}>Ref (gr)</th>
          <th style={cellStyle}>Sonuç (%)</th>
          <th style={cellStyle}>Sonuç (gr)</th>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>1. Elek</td>
          <td style={cellStyle}>{"%8-10"}</td>
          <td style={cellStyle}>{mathRound(props.baitTable.sample * 0.1)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (props.baitTable.firstSieve / props.baitTable.sample) * 100 >=
                  8 &&
                (props.baitTable.firstSieve / props.baitTable.sample) * 100 <=
                  10
                  ? "#92d050"
                  : "#fc0404",
            }}
          >
            %
            {props.baitTable.sample > 0
              ? mathRound(
                  (props.baitTable.firstSieve / props.baitTable.sample) * 100
                )
              : 0}
          </td>
          <td>{props.baitTable.firstSieve}</td>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>2. Elek</td>
          <td style={cellStyle}>{"%30-50"}</td>
          <td style={cellStyle}>{mathRound(props.baitTable.sample * 0.35)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (props.baitTable.secondSieve / props.baitTable.sample) * 100 >=
                  30 &&
                (props.baitTable.secondSieve / props.baitTable.sample) * 100 <=
                  50
                  ? "#92d050"
                  : "#fc0404",
            }}
          >
            %
            {props.baitTable.sample > 0
              ? mathRound(
                  (props.baitTable.secondSieve / props.baitTable.sample) * 100
                )
              : 0}
          </td>
          <td>{props.baitTable.secondSieve}</td>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>3. Elek</td>
          <td style={cellStyle}>{"%30-50"}</td>
          <td style={cellStyle}>{mathRound(props.baitTable.sample * 0.35)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (props.baitTable.thirdSieve / props.baitTable.sample) * 100 >=
                  30 &&
                (props.baitTable.thirdSieve / props.baitTable.sample) * 100 <=
                  50
                  ? "#92d050"
                  : "#fc0404",
            }}
          >
            %
            {props.baitTable.sample > 0
              ? mathRound(
                  (props.baitTable.thirdSieve / props.baitTable.sample) * 100
                )
              : 0}
          </td>
          <td>{props.baitTable.thirdSieve}</td>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>4. Elek</td>
          <td style={cellStyle}>{">%20"}</td>
          <td style={cellStyle}>{mathRound(props.baitTable.sample * 0.1)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (props.baitTable.fourthSieve / props.baitTable.sample) * 100 >
                20
                  ? "#fc0404"
                  : "#92d050",
            }}
          >
            %
            {props.baitTable.sample > 0
              ? mathRound(
                  (props.baitTable.fourthSieve / props.baitTable.sample) * 100
                )
              : 0}
          </td>
          <td>{props.baitTable.fourthSieve}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default FeedScreenTable;
