import { Form, Input, InputNumber, Select } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import {
  animalExtendedApi,
  birthCrudApi,
  milkRateCrudApi,
  seminationCrudApi,
  syncExtendedApi,
} from "../../client/EntityApi";
import DatePick from "../../core/component/DatePick";
import YesNoTag from "../../core/component/YesNoTag";
import {
  cellStyle,
  getConstantValue,
  getDateString,
} from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";
import BaseFormList from "../BaseFormList";

const { TextArea } = Input;

export const getAnimalCPColumns = (
  paddocks: any[],
  animalStatus: any[],
  remainderState: string
) => {
  return [
    {
      title: "Küpe No",
      dataIndex: "earringNo",
      key: "earringNo",
    },
    {
      title: "İşletme Id",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Yaş/Gün",
      dataIndex: "birthDay",
      key: "birthDay",
      sorter: (a: any, b: any) => a.birthDay - b.birthDay,
    },
    {
      title: "Padok",
      dataIndex: "paddock",
      key: "paddock",
      filters: paddocks,
      onFilter: (value: any, record: any) => record.paddock === value,
    },
    {
      title: "Son Tohumlama Tarihi",
      dataIndex: "lastSemination",
      key: "lastSemination",
      render: (value: any) =>
        value ? getDateString(value.date) : "Tohum Atılmadı",
    },
    {
      title: "Son Doğurma Tarihi",
      dataIndex: "lastBirth",
      key: "lastBirth",
      render: (value: any) =>
        value ? getDateString(value.date) : "Doğum Yapmadı",
    },
    {
      title: "SGS",
      dataIndex: "dsg",
      key: "dsg",
      sorter: (a: any, b: any) => a.dsg - b.dsg,
    },
    {
      title: "pSGS",
      dataIndex: "sgs",
      key: "sgs",
      sorter: (a: any, b: any) => a.sgs - b.sgs,
    },
    {
      title: "GSV",
      dataIndex: "gsv",
      key: "gsv",
      sorter: (a: any, b: any) => a.gsv - b.gsv,
    },
    {
      title: "TS",
      dataIndex: "ts",
      key: "ts",
      sorter: (a: any, b: any) => a.ts - b.ts,
    },
    {
      title: "Gebelik",
      dataIndex: "pregnancyDay",
      key: "pregnancyDay",
      sorter: (a: any, b: any) => a.pregnancyDay - b.pregnancyDay,
    },
    {
      title: "Durum",
      dataIndex: "state",
      key: "state",
      filters: animalStatus,
      onFilter: (value: any, record: any) => record.state === value,
    },
    {
      title: "Kalan Süre",
      key: "remainderTime",
      sorter: (a: any, b: any) => a[remainderState] - b[remainderState],
      render: (value: any, row: any) => row[remainderState] ?? 0,
    },
    {
      title: "Sürüde",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any) => (
        <YesNoTag
          success={value}
          successText="Sürüde"
          failText="Sürüden Çıkarılmış"
        />
      ),
      defaultFilteredValue: [true],
      filters: [
        {
          text: "Sürüde",
          value: true,
        },
        {
          text: "Sürüden Çıkarılmış",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.isActive === value,
    },
  ];
};

export const getAnimalCPFormItems = (
  id: any,
  genders: any[],
  racials: any[],
  seminationStates: any[],
  birthTypes: any[],
  vaccines: any[]
) => {
  return (
    <>
      <Form.Item
        label="Küpe No"
        name="earringNo"
        rules={[Rules.required, Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="İşletme Id" name="companyName" rules={[Rules.max(255)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Padok Adı" name="paddock" rules={[Rules.max(255)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Ana Adı" name="mother" rules={[Rules.max(255)]}>
        <Input />
      </Form.Item>
      <Form.Item label="Baba Adı" name="father" rules={[Rules.max(255)]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Doğum Ağırlığı"
        name="birthWeight"
        initialValue="0"
        rules={[Rules.required]}
      >
        <InputNumber precision={3} min={0} stringMode />
      </Form.Item>
      <Form.Item
        label="Sütten Kesme Ağırlığı"
        name="weaningWeight"
        initialValue="0"
        rules={[Rules.required]}
      >
        <InputNumber precision={3} min={0} stringMode />
      </Form.Item>
      <Form.Item label="Cinsiyet" name="gender" rules={[Rules.required]}>
        <Select options={genders} />
      </Form.Item>
      <Form.Item label="Irk" name="racial" rules={[Rules.required]}>
        <Select options={racials} />
      </Form.Item>
      <Form.Item label="Doğum Tarihi" name="birthDate" rules={[Rules.required]}>
        <DatePick />
      </Form.Item>
      <Form.Item
        label="Sürüde mi?"
        name="isActive"
        initialValue={true}
        rules={[Rules.required]}
      >
        <Select
          options={[
            { label: "Sürüde", value: true },
            { label: "Sürüden Çıkarılmış", value: false },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Sürüden Çık. Sebebi"
        name="description"
        rules={[Rules.max(2000)]}
      >
        <TextArea
          placeholder="Sadece sürüden çıkartılan hayvanlar için açıklama giriniz. Sürüden çıkartılmamış bir hayvan için girilen açıklamalar yoksayılacaktır."
          rows={4}
        />
      </Form.Item>
      <Form.Item
        hidden
        name="company"
        rules={[Rules.required]}
        initialValue={"api/companies/" + id}
      >
        <Input />
      </Form.Item>
      <BaseFormList
        name="seminations"
        addButtonText="Tohum Ekle"
        title="Tohum Bilgileri"
        formItems={(name) => getSeminationForm(name, seminationStates)}
      />
      <BaseFormList
        name="births"
        addButtonText="Doğum Ekle"
        title="Doğum Bilgileri"
        formItems={(name) => getBirthForm(name, birthTypes)}
      />
      <BaseFormList
        name="milkRates"
        addButtonText="Süt Verisi Ekle"
        title="Süt Verisi"
        formItems={(name) => getMilRateForm(name)}
      />
      <BaseFormList
        name="syncs"
        addButtonText="Senkronizasyon Ekle"
        title="Senkronizasyon Bilgileri"
        formItems={(name) => getSyncForm(name)}
      />
      <BaseFormList
        name="individualVaccines"
        addButtonText="Aşı Ekle"
        title="Aşı Bilgileri"
        formItems={(name) => getIndividualVaccineForm(name, vaccines)}
      />
    </>
  );
};

export const extraProcessFormOnOk = (values: any, formName?: string) => {
  switch (formName) {
    case "usgForm":
      return seminationCrudApi.edit(values.id, values);
    case "syncForm":
      values.animals = values.animals.map((animalIri: string) => {
        return parseInt(animalIri.split("/")[2]);
      });
      return syncExtendedApi.startSync(values);
    case "milkRateForm":
      return milkRateCrudApi.create(values);
    case "birthForm":
      return birthCrudApi.create(values);
    case "seminationForm":
      return seminationCrudApi.create(values);
    case "individualVaccineForm":
      return animalExtendedApi.makeIndividualVaccine(values);
  }
};

export const getExtraProcessFormTitle = (formName?: string) => {
  switch (formName) {
    case "usgForm":
      return "Usg Gir";
    case "syncForm":
      return "Senkronizasyon Başlat";
    case "milkRateForm":
      return "Süt Verisi Gir";
    case "birthForm":
      return "Doğum Ekle";
    case "seminationForm":
      return "Tohum At";
    case "individualVaccineForm":
      return "Aşı Yap";
    default:
      return <></>;
  }
};

export const getExtraProcessFormItems = (
  formName?: string,
  formVariables?: any
) => {
  switch (formName) {
    case "usgForm":
      return (
        <>
          <Form.Item label="USG1" name="usg1" initialValue="none">
            <Select options={formVariables.seminationStates} />
          </Form.Item>
          <Form.Item
            label="USG1'i Yapan"
            name="usg1Worker"
            rules={[Rules.max(255)]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="USG2" name="usg2" initialValue="none">
            <Select options={formVariables.seminationStates} />
          </Form.Item>
          <Form.Item
            label="USG2'i Yapan"
            name="usg2Worker"
            rules={[Rules.max(255)]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="id" rules={[Rules.required]}>
            <Input hidden />
          </Form.Item>
        </>
      );
    case "syncForm":
      return (
        <>
          <Form.Item label="Hayvan" name="animals" rules={[Rules.required]}>
            <Select
              showSearch
              mode="multiple"
              options={formVariables.mappedReadySyncAnimals}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            label="Başlangıç Tarihi"
            name="date"
            rules={[Rules.required]}
          >
            <DatePick />
          </Form.Item>
        </>
      );
    case "milkRateForm":
      return (
        <>
          <Form.Item label="Hayvan" name="animal" rules={[Rules.required]}>
            <Select
              showSearch
              options={formVariables.mappedAnimals}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item label="Veri" name="data" rules={[Rules.required]}>
            <InputNumber precision={1} min={0} stringMode />
          </Form.Item>
          <Form.Item label="Veri Tarihi" name="date" rules={[Rules.required]}>
            <DatePick />
          </Form.Item>
        </>
      );
    case "birthForm":
      return (
        <>
          <Form.Item
            label="Doğuran Hayvan"
            name="animal"
            rules={[Rules.required]}
          >
            <Select
              showSearch
              options={formVariables.mappedAnimals}
              onSelect={(_value: any, values: any) => {
                const date = formVariables.form.getFieldValue("date");
                const lastBirthDate = moment(values.lastBirth);
                if (date && lastBirthDate) {
                  const dayDiff = date.diff(lastBirthDate, "days");
                  if (dayDiff >= 0 && dayDiff < 250) {
                    formVariables.form.resetFields(["date"]);
                    toast.error(
                      "Son doğumun üzerinden henüz 250 gün geçmedi. Lütfen geçerli bir doğum tarihi giriniz."
                    );
                  }
                }
              }}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item label="Doğum Tipi" name="type" rules={[Rules.required]}>
            <Select
              options={formVariables.birthTypes}
              onChange={(value) => {
                if (
                  value &&
                  ["abort", "stillbirth"].includes(value as string)
                ) {
                  formVariables.form.resetFields(["births"]);
                }
              }}
            />
          </Form.Item>
          <Form.Item label="Doğum Tarihi" name="date" rules={[Rules.required]}>
            <DatePick
              onChange={(value: any) => {
                const animalIri = formVariables.form.getFieldValue("animal");
                if (animalIri) {
                  const findedAnimal = formVariables.mappedAnimals.find(
                    (animal: any) => {
                      return animal.value === animalIri;
                    }
                  );
                  if (findedAnimal && findedAnimal.lastBirth && value) {
                    const lastBirthDate = moment(findedAnimal.lastBirth);

                    const dayDiff = value.diff(lastBirthDate, "days");
                    if (dayDiff >= 0 && dayDiff < 250) {
                      toast.error(
                        "Son doğumun üzerinden henüz 250 gün geçmedi. Lütfen geçerli bir doğum tarihi giriniz."
                      );
                      formVariables.form.resetFields(["date"]);
                    }
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Açıklama"
            name="description"
            rules={[Rules.max(1000)]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <BaseFormList
            name="births"
            title="Yavru Bilgileri"
            addButtonText="Yavru Ekle"
            onClick={(add) => {
              const formValues = formVariables.form.getFieldsValue();
              [undefined, "abort", "stillbirth"].includes(formValues.type)
                ? toast.error("Girilen doğum tipi için yavru eklenemez.")
                : add({
                    birthWeight: "0",
                    weaningWeight: "0",
                    isActive: true,
                  });
            }}
            formItems={(name) => (
              <>
                <Form.Item
                  label="İşletme Id"
                  name={[name, "companyName"]}
                  rules={[Rules.max(255)]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Padok Adı"
                  name={[name, "paddock"]}
                  rules={[Rules.max(255)]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Doğum Ağırlığı"
                  name={[name, "birthWeight"]}
                  rules={[Rules.required]}
                >
                  <InputNumber precision={3} min={0} stringMode />
                </Form.Item>
                <Form.Item
                  label="Sütten Kesme Ağırlığı"
                  name={[name, "weaningWeight"]}
                  rules={[Rules.required]}
                >
                  <InputNumber precision={3} min={0} stringMode />
                </Form.Item>
                <Form.Item
                  label="Cinsiyet"
                  name={[name, "gender"]}
                  rules={[Rules.required]}
                >
                  <Select options={formVariables.genders} />
                </Form.Item>
                <Form.Item
                  label="Irk"
                  name={[name, "racial"]}
                  rules={[Rules.required]}
                >
                  <Select options={formVariables.racials} />
                </Form.Item>
                <Form.Item
                  label="Sürüde mi?"
                  name={[name, "isActive"]}
                  initialValue={true}
                  rules={[Rules.required]}
                >
                  <Select
                    options={[
                      { label: "Sürüde", value: true },
                      { label: "Sürüden Çıkarılmış", value: false },
                    ]}
                  />
                </Form.Item>
              </>
            )}
          />
        </>
      );
    case "seminationForm":
      return (
        <>
          <Form.Item
            label="Tohumlanacak Olan"
            name="animal"
            rules={[Rules.required]}
          >
            <Select
              showSearch
              options={formVariables.mappedAnimals}
              onSelect={(_value: any, values: any) => {
                if (values.lastSeminationState === "pregnant") {
                  formVariables.form.resetFields(["animal"]);
                  toast.error(
                    "Gebe hayvana tohum atılamaz, lütfen gebe olmayan bir hayvan seçiniz."
                  );
                  return;
                }
                const date = formVariables.form.getFieldValue("date");
                const lastBirthDate = moment(values.lastBirth);
                if (date && lastBirthDate) {
                  const dayDiff = date.diff(lastBirthDate, "days");
                  if (dayDiff >= 0 && dayDiff < 15) {
                    formVariables.form.resetFields(["date"]);
                    toast.error(
                      "Girilen tohumlama tarihi son doğum tarihinden en az 15 gün büyük olmalıdır."
                    );
                  }
                }
              }}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            label="Sperma"
            name="sperma"
            rules={[Rules.required, Rules.max(255)]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Tohum Tarihi" name="date" rules={[Rules.required]}>
            <DatePick
              onChange={(value: any) => {
                const animalIri = formVariables.form.getFieldValue("animal");
                if (animalIri) {
                  const findedAnimal = formVariables.mappedAnimals.find(
                    (animal: any) => animal.value === animalIri
                  );
                  if (findedAnimal && findedAnimal.lastBirth && value) {
                    const lastBirthDate = moment(findedAnimal.lastBirth);
                    const dayDiff = value.diff(lastBirthDate, "days");
                    if (dayDiff >= 0 && dayDiff < 15) {
                      toast.error(
                        "Girilen tohumlama tarihi son doğum tarihinden en az 15 gün büyük olmalıdır."
                      );
                      formVariables.form.resetFields(["date"]);
                    }
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Tohum Atan"
            name="seminationWorker"
            rules={[Rules.max(255)]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="USG1" name="usg1" initialValue="none">
            <Select options={formVariables.seminationStates} />
          </Form.Item>
          <Form.Item
            label="USG1'i Yapan"
            name="usg1Worker"
            rules={[Rules.max(255)]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="USG2" name="usg2" initialValue="none">
            <Select options={formVariables.seminationStates} />
          </Form.Item>
          <Form.Item
            label="USG2'i Yapan"
            name="usg2Worker"
            rules={[Rules.max(255)]}
          >
            <Input />
          </Form.Item>
        </>
      );
    case "individualVaccineForm":
      return (
        <>
          <Form.Item
            label="Aşılanacak Olanlar"
            name="animals"
            rules={[Rules.required]}
          >
            <Select
              showSearch
              mode="multiple"
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              options={formVariables.mappedReadyVaccineAnimals}
            />
          </Form.Item>
          <Form.Item
            label="Aşı Tipi"
            name="vaccineName"
            rules={[Rules.required]}
          >
            <Select showSearch options={formVariables.mappedVaccines} />
          </Form.Item>
          <Form.Item
            label="Yapılma Tarihi"
            name="date"
            rules={[Rules.required]}
          >
            <DatePick />
          </Form.Item>
          <Form.Item
            label="Açıklama"
            name="description"
            rules={[Rules.required, Rules.max(500)]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </>
      );
    default:
      return <></>;
  }
};

export const getAnimalCPExpandedRowRender = (
  record: any,
  genders: any[],
  racials: any[],
  seminationStates: any[],
  birthTypes: any[]
) => {
  return (
    <>
      <table style={{ minWidth: "100%", marginBottom: "1em" }}>
        <thead>
          <tr>
            <th style={cellStyle}>Yaş (Gün)</th>
            <th style={cellStyle}>Yaş (Ay)</th>
            <th style={cellStyle}>Yaş (Yıl)</th>
            <th style={cellStyle}>Doğum Tarihi</th>
            <th style={cellStyle}>Cinsiyet</th>
            <th style={cellStyle}>Irk</th>
            <th style={cellStyle}>Padok</th>
            <th style={cellStyle}>Ana Adı</th>
            <th style={cellStyle}>Baba Adı</th>
            <th style={cellStyle}>Doğum Ağırlığı</th>
            <th style={cellStyle}>Sütten Kesme Ağırlığı</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th style={cellStyle}>{record.birthDay}</th>
            <th style={cellStyle}>{record.birthMonth}</th>
            <th style={cellStyle}>{record.birthYear}</th>
            <th style={cellStyle}>{getDateString(record.birthDate)}</th>
            <th style={cellStyle}>
              {getConstantValue(record.gender, genders)}
            </th>
            <th style={cellStyle}>
              {getConstantValue(record.racial, racials)}
            </th>
            <th style={cellStyle}>{record.paddock}</th>
            <th style={cellStyle}>{record.mother}</th>
            <th style={cellStyle}>{record.father}</th>
            <th style={cellStyle}>{record.birthWeight}</th>
            <th style={cellStyle}>{record.weaningWeight}</th>
          </tr>
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={7}>
              Tohum Bilgileri ({record.seminations.length} Defa Tohumlandı)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Sperma</th>
            <th style={cellStyle}>Tohum Atan</th>
            <th style={cellStyle}>USG1</th>
            <th style={cellStyle}>USG1'i Yapan</th>
            <th style={cellStyle}>USG2</th>
            <th style={cellStyle}>USG2'i Yapan</th>
            <th style={cellStyle}>Tohum Tarihi</th>
          </tr>
          {record.seminations.map((semination: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>{semination.sperma}</th>
                <th style={cellStyle}>
                  {semination.seminationWorker
                    ? semination.seminationWorker
                    : "BELİRSİZ"}
                </th>
                <th style={cellStyle}>
                  {getConstantValue(semination.usg1, seminationStates)}
                </th>
                <th style={cellStyle}>{semination.usg1Worker}</th>
                <th style={cellStyle}>
                  {getConstantValue(semination.usg2, seminationStates)}
                </th>
                <th style={cellStyle}>{semination.usg2Worker}</th>
                <th style={cellStyle}>{getDateString(semination.date)}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Doğum Bilgileri ({record.births.length} Defa Doğurdu)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Doğum Tipi</th>
            <th style={cellStyle}>Açıklaması</th>
            <th style={cellStyle}>Doğurma Tarihi</th>
          </tr>
          {record.births.map((birth: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>
                  {getConstantValue(birth.type, birthTypes)}
                </th>
                <th style={cellStyle}>{birth.description}</th>
                <th style={cellStyle}>{getDateString(birth.date)}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Süt Bilgileri ({record.milkRates.length} Kayıt Mevcut)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Veri</th>
            <th style={cellStyle}>Tarih</th>
          </tr>
          {record.milkRates.map((milkRate: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>{milkRate.data}</th>
                <th style={cellStyle}>{getDateString(milkRate.date)}</th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Senkronizasyon Bilgileri ({record.syncs.length} Kayıt Mevcut)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Başlangıç T.</th>
            <th style={cellStyle}>PG Tarihi</th>
            <th style={cellStyle}>GNRH2 Tarihi</th>
            <th style={cellStyle}>Tohumlama Tarihi</th>
            <th style={cellStyle}>Senkronizasyonun Bittiği Tarih</th>
          </tr>
          {record.syncs.map((sync: any, index: number) => {
            return (
              <tr style={cellStyle} key={index}>
                <th style={cellStyle}>{getDateString(sync.date)}</th>
                <th style={cellStyle}>{getDateString(sync.pg)}</th>
                <th style={cellStyle}>{getDateString(sync.gnrh2)}</th>
                <th style={cellStyle}>{getDateString(sync.semination)}</th>
                <th style={cellStyle}>
                  {sync.passiveDate ? getDateString(sync.passiveDate) : "-"}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        style={{
          width: "50%",
          textAlign: "center",
          borderCollapse: "collapse",
          border: "1px solid black",
          margin: "1em 0px 1em 0px",
        }}
      >
        <thead>
          <tr style={cellStyle}>
            <th style={cellStyle} colSpan={5}>
              Aşı Bilgileri ({record.individualVaccines.length} Kayıt Mevcut)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cellStyle}>
            <th style={cellStyle}>Aşı İsmi</th>
            <th style={cellStyle}>Açıklaması</th>
            <th style={cellStyle}>Yapılma Tarihi</th>
          </tr>
          {record.individualVaccines.map(
            (individualVaccine: any, index: number) => {
              return (
                <tr style={cellStyle} key={index}>
                  <th style={cellStyle}>{individualVaccine.name}</th>
                  <th style={cellStyle}>{individualVaccine.description}</th>
                  <th style={cellStyle}>
                    {individualVaccine.date
                      ? getDateString(individualVaccine.date)
                      : "-"}
                  </th>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      {record.description && (
        <p style={{ color: "red" }}>
          <b>Sürüden Çıkartılma Sebebi</b> : {record.description}
        </p>
      )}
    </>
  );
};

const getIndividualVaccineForm = (name: any, vaccines: any[]) => {
  return (
    <>
      <Form.Item
        label="Aşı Tipi"
        name={[name, "name"]}
        rules={[Rules.required]}
      >
        <Select options={vaccines} />
      </Form.Item>
      <Form.Item
        label="Yapılma Tarihi"
        name={[name, "date"]}
        rules={[Rules.required]}
      >
        <DatePick />
      </Form.Item>
      <Form.Item
        label="Açıklama"
        name={[name, "description"]}
        rules={[Rules.required, Rules.max(500)]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </>
  );
};

const getSyncForm = (name: any) => {
  return (
    <>
      <Form.Item
        label="Başlangıç Tarihi"
        name={[name, "date"]}
        rules={[Rules.required]}
      >
        <DatePick />
      </Form.Item>
    </>
  );
};

const getMilRateForm = (name: any) => {
  return (
    <>
      <Form.Item label="Veri" name={[name, "data"]} rules={[Rules.required]}>
        <InputNumber precision={1} min={0} stringMode />
      </Form.Item>
      <Form.Item label="Tarih" name={[name, "date"]} rules={[Rules.required]}>
        <DatePick />
      </Form.Item>
    </>
  );
};

const getBirthForm = (name: any, birthTypes: any[]) => {
  return (
    <>
      <Form.Item
        label="Doğum Tipi"
        name={[name, "type"]}
        rules={[Rules.required]}
      >
        <Select options={birthTypes} />
      </Form.Item>
      <Form.Item
        label="Doğum Tarihi"
        name={[name, "date"]}
        rules={[Rules.required]}
      >
        <DatePick />
      </Form.Item>
      <Form.Item
        label="Açıklama"
        name={[name, "description"]}
        rules={[Rules.max(1000)]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </>
  );
};

const getSeminationForm = (name: any, seminationStates: any[]) => {
  return (
    <>
      <Form.Item
        label="Sperma"
        name={[name, "sperma"]}
        rules={[Rules.required, Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tohum Tarihi"
        name={[name, "date"]}
        rules={[Rules.required]}
      >
        <DatePick />
      </Form.Item>
      <Form.Item
        label="Tohum Atan"
        name={[name, "seminationWorker"]}
        rules={[Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="USG1" name={[name, "usg1"]}>
        <Select options={seminationStates} />
      </Form.Item>
      <Form.Item
        label="USG1'i Yapan"
        name={[name, "usg1Worker"]}
        rules={[Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="USG2" name={[name, "usg2"]}>
        <Select options={seminationStates} />
      </Form.Item>
      <Form.Item
        label="USG2'i Yapan"
        name={[name, "usg2Worker"]}
        rules={[Rules.max(255)]}
      >
        <Input />
      </Form.Item>
    </>
  );
};
