import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  animalCrudApi,
  animalExtendedApi,
  companyCrudApi,
  vaccineCrudApi,
} from "../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { SyncOutlined } from "@ant-design/icons";
import {
  extraProcessFormOnOk,
  getAnimalCPColumns,
  getAnimalCPExpandedRowRender,
  getAnimalCPFormItems,
  getExtraProcessFormItems,
  getExtraProcessFormTitle,
} from "../components/crud/AnimalCPComponents";
import { otherApi } from "../core/client/BaseEntityApi";
import { getDateFromString, unique } from "../core/helper/UtilHelper";
import { Form, Modal, Select } from "antd";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import { useForm } from "antd/lib/form/Form";
import SyringeIcon from "../Icons/SyringeIcon";
import MilkTankIcon from "../Icons/MilkTankIcon";
import BabyIcon from "../Icons/BabyIcon";
import SpermIcon from "../Icons/SpermIcon";
import UltrasoundIcon from "../Icons/UltrasoundIcon";
import AnimalReportBar from "../components/AnimalReportBar";
import { AuthHelper } from "../core/helper/AuthHelper";
import VaccineIcon from "../Icons/VaccineIcon";
import { EyeOutlined } from "@ant-design/icons";

const AnimalCrudPage = () => {
  const { id } = useParams();

  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [company, setCompany] = useState<any>();
  const [paddocks, setPaddocks] = useState<any[]>([]);
  const [animalStatus, setAnimalStatus] = useState([]);
  const [genders, setGenders] = useState([]);
  const [racials, setRacials] = useState([]);
  const [seminationStates, setSeminationStates] = useState([]);
  const [birthTypes, setBirthTypes] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [remainderState, setRemainderState] = useState("dryRemainder");

  const [extraProcessForm] = useForm();
  const [extraProcessName, setExtraProcessName] = useState<string>();
  const [extraProcessModalIsOpen, setExtraProcessModalIsOpen] = useState(false);
  const [extraProcessLoading, setExtraProcessLoading] = useState(false);

  const [report, setReport] = useState<any>();

  const [mappedAnimals, setMappedAnimals] = useState<any[]>([]);
  const [mappedReadyVaccineAnimals, setMappedReadyVaccineAnimals] = useState<
    any[]
  >([]);
  const [mappedReadySyncAnimals, setMappedReadySyncAnimals] = useState<any[]>(
    []
  );

  useEffect(() => {
    otherApi.constants().then((response) => {
      setAnimalStatus(response["animalStatus"]);
      setGenders(response["gender"]);
      setRacials(response["racial"]);
      setSeminationStates(response["seminationState"]);
      setBirthTypes(response["birthType"]);
    });
    vaccineCrudApi.getAll().then((response) => {
      setVaccines(
        response["hydra:member"].map((vaccine: any) => {
          return { key: vaccine.name, value: vaccine.name };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (id) {
      companyCrudApi.get(parseInt(id)).then((response) => setCompany(response));
    }
  }, [id]);

  const getCompanyAnimals = () => {
    return id
      ? animalExtendedApi.getCompanyAnimals(parseInt(id))
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  const formItems = getAnimalCPFormItems(
    id,
    genders,
    racials,
    seminationStates,
    birthTypes,
    vaccines
  );

  const extraProcessModalOpen = (formName?: string) => {
    setExtraProcessName(formName);
    setExtraProcessModalIsOpen(true);
  };

  const extraProcessModalOnCancel = () => {
    setExtraProcessModalIsOpen(false);
    setExtraProcessLoading(false);
    setExtraProcessName(undefined);
    extraProcessForm.resetFields();
  };

  return (
    <>
      <AnimalReportBar report={report} />
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        customTableTitle={() =>
          company ? company.name + " Hayvan Listesi" : <SyncOutlined spin />
        }
        getAll={getCompanyAnimals}
        api={animalCrudApi}
        searchKeys={["earringNo", "companyName"]}
        columns={getAnimalCPColumns(paddocks, animalStatus, remainderState)}
        addFormItems={formItems}
        editFormItems={formItems}
        extraTitleProcess={() => {
          return (
            <>
              <Select
                style={{ marginRight: "1em" }}
                onSelect={(value: string) => setRemainderState(value)}
                options={[
                  { label: "Kuru", value: "dryRemainder" },
                  { label: "CloseUp", value: "closeupRemainder" },
                  { label: "Fresh", value: "freshRemainder" },
                  { label: "Sütten Kes.", value: "weaningRemainder" },
                  { label: "Doğuma", value: "birthRemainder" },
                ]}
                defaultValue="dryRemainder"
              />
              <CrudPageProcessButton
                tooltipText="Senkronizasyon Başlat"
                icon={<SyringeIcon />}
                onClick={() => extraProcessModalOpen("syncForm")}
              />
              <CrudPageProcessButton
                tooltipText="Süt Verisi Ekle"
                icon={<MilkTankIcon />}
                onClick={() => extraProcessModalOpen("milkRateForm")}
              />
              <CrudPageProcessButton
                tooltipText="Doğum Ekle"
                icon={<BabyIcon />}
                onClick={() => extraProcessModalOpen("birthForm")}
              />
              <CrudPageProcessButton
                tooltipText="Tohum At"
                icon={<SpermIcon />}
                onClick={() => extraProcessModalOpen("seminationForm")}
              />
              <CrudPageProcessButton
                tooltipText="Aşı Yap"
                icon={<VaccineIcon />}
                onClick={() => extraProcessModalOpen("individualVaccineForm")}
              />
              <CrudPageProcessButton
                tooltipText="İşletme Detayı"
                icon={<EyeOutlined />}
                onClick={() => window.open("/isletme-detay/" + id)}
              />
            </>
          );
        }}
        extraRowProcess={(row) => (
          <CrudPageProcessButton
            tooltipText="Ultrason Gir"
            disabled={row.lastSeminationState === "non-pregnant"}
            icon={<UltrasoundIcon />}
            onClick={() => {
              extraProcessForm.setFieldsValue({
                id: row.lastSemination.id,
                usg1: row.lastSemination.usg1,
                usg1Worker: row.lastSemination.usg1Worker,
                usg2: row.lastSemination.usg2,
                usg2Worker: row.lastSemination.usg2Worker,
              });
              extraProcessModalOpen("usgForm");
            }}
          />
        )}
        expandable={{
          expandedRowRender: (record) =>
            getAnimalCPExpandedRowRender(
              record,
              genders,
              racials,
              seminationStates,
              birthTypes
            ),
        }}
        afterRefreshOperation={(data) => {
          if (id) {
            setReport(undefined);
            animalExtendedApi
              .getCompanyAnimalReport(parseInt(id))
              .then((response) => setReport(response));
          }
          const paddocks = data.map((animal) => animal.paddock);
          const mappedPaddocks = unique(paddocks).map((paddockName) => {
            return {
              text: paddockName,
              value: paddockName,
            };
          });
          setPaddocks(mappedPaddocks);

          const mappedAnimals = data
            .filter(
              (animal) => animal.gender !== "male" && animal.isActive !== false
            )
            .map((animal) => {
              return {
                label:
                  animal.earringNo +
                  (animal.companyName === null
                    ? ""
                    : " - " + animal.companyName),
                key: animal.earringNo,
                value: "api/animals/" + animal.id,
                lastBirth: animal.lastBirth?.date,
                lastSemination: animal.lastSemination?.date,
                lastSeminationState: animal.lastSeminationState,
                syncState: animal.syncState,
                readyVaccine: animal.readyVaccine,
              };
            });

          const mappedReadyVaccineAnimals = data
            .filter((animal) => animal.readyVaccine)
            .map((animal) => {
              return {
                label:
                  animal.earringNo +
                  (animal.companyName === null
                    ? ""
                    : " - " + animal.companyName),
                value: "api/animals/" + animal.id,
              };
            });

          const readySyncAnimals = mappedAnimals.filter(
            (mappedAnimal: any) => mappedAnimal.syncState === "readySync"
          );
          setMappedReadySyncAnimals(readySyncAnimals);
          setMappedReadyVaccineAnimals(mappedReadyVaccineAnimals);
          setMappedAnimals(mappedAnimals);
        }}
        setEditFields={(row) => {
          const workedBirths = row.births.map((birth: any) => {
            return {
              ...birth,
              id: birth["@id"],
              date: getDateFromString(birth.date),
            };
          });

          const workedSeminations = row.seminations.map((semination: any) => {
            return {
              ...semination,
              id: semination["@id"],
              date: getDateFromString(semination.date),
            };
          });

          const workedMilkRates = row.milkRates.map((milkRate: any) => {
            return {
              ...milkRate,
              id: milkRate["@id"],
              date: getDateFromString(milkRate.date),
            };
          });

          const workedSyncs = row.syncs.map((sync: any) => {
            return {
              ...sync,
              id: sync["@id"],
              date: getDateFromString(sync.date),
            };
          });

          const workedIndividualVaccines = row.individualVaccines.map(
            (individualVaccine: any) => {
              return {
                ...individualVaccine,
                id: individualVaccine["@id"],
                date: getDateFromString(individualVaccine.date),
              };
            }
          );

          return {
            company: row.company["@id"],
            birthDate: getDateFromString(row.birthDate),
            births: workedBirths,
            seminations: workedSeminations,
            milkRates: workedMilkRates,
            syncs: workedSyncs,
            individualVaccines: workedIndividualVaccines,
          };
        }}
        hideEditProcess={
          !AuthHelper.checkVisibility("Hayvan Bilgisi Düzenleme")
        }
      />
      <Modal
        title={getExtraProcessFormTitle(extraProcessName)}
        visible={extraProcessModalIsOpen}
        okText="Ekle"
        cancelText="Vazgeç"
        onCancel={extraProcessModalOnCancel}
        onOk={() => {
          extraProcessForm.validateFields().then((values) => {
            setExtraProcessLoading(true);
            extraProcessFormOnOk(values, extraProcessName)
              ?.then(() => crudPageRef.current?.refreshData())
              .finally(() => extraProcessModalOnCancel());
          });
        }}
        confirmLoading={extraProcessLoading}
      >
        <Form form={extraProcessForm} layout="vertical">
          {getExtraProcessFormItems(extraProcessName, {
            form: extraProcessForm,
            seminationStates: seminationStates,
            racials: racials,
            genders: genders,
            birthTypes: birthTypes,
            mappedReadySyncAnimals: mappedReadySyncAnimals,
            mappedReadyVaccineAnimals: mappedReadyVaccineAnimals,
            mappedAnimals: mappedAnimals,
            mappedVaccines: vaccines,
          })}
        </Form>
      </Modal>
    </>
  );
};

export default AnimalCrudPage;
