import api from "../core/client/AxiosConfig";
import { getCrudApi } from "../core/client/CrudApi";

export const businessTaskCrudApi = getCrudApi("business-tasks");
export const priceCrudApi = getCrudApi("price-lists");
export const taskCategoryCrudApi = getCrudApi("task-categories");
export const primPoolCrudApi = getCrudApi("prim-pools");
export const userBonusCrudApi = getCrudApi("user-bonuses");
export const serviceCrudApi = getCrudApi("services");
export const taskCrudApi = getCrudApi("tasks");
export const seminationCrudApi = getCrudApi("seminations");
export const birthCrudApi = getCrudApi("births");
export const milkRateCrudApi = getCrudApi("milk-rates");

export const consumptionCrudApi = getCrudApi("consumptions");
export const consumptionProductCrudApi = getCrudApi("consumption-products");
export const consumptionExtendedApi = {
  getCompanyConsumptions: (companyId: number) => {
    return api
      .get("api/companies/" + companyId + "/consumptions")
      .then((response: any) => response.data);
  },
};

export const syncExtendedApi = {
  startSync: (values: any) => {
    return api.post("api/sync/start", values).then((response: any) => {
      return response.data;
    });
  },
};

export const animalCrudApi = getCrudApi("animals");
export const animalExtendedApi = {
  getCompanyAnimals: (companyId: number) => {
    return api
      .get("api/companies/" + companyId + "/animals")
      .then((response: any) => {
        return response.data;
      });
  },
  getCompanyAnimalReport: (companyId: number) => {
    return api
      .get("api/companies/" + companyId + "/animals/report")
      .then((response: any) => {
        return response.data;
      });
  },
  makeIndividualVaccine: (values: any) => {
    return api
      .post("api/make-individual-vaccine", values)
      .then((response: any) => response.data);
  },
};

export const vaccineCrudApi = getCrudApi("vaccines");
export const vaccineExtendedApi = {
  getVaccineLogs: (id: number) => {
    return api
      .get("api/vaccine-logs-for-company/" + id)
      .then((response: any) => {
        return response.data;
      });
  },
};

export const companyCrudApi = getCrudApi("companies");
export const companyExtendedApi = {
  getUserReports: (periodId: number) => {
    return api
      .get("/api/user-report/" + periodId)
      .then((response) => response.data);
  },
  getUserTaskCategories: (periodId: number) => {
    return api
      .get("/api/user/task-categories-with-period/" + periodId)
      .then((response) => response.data);
  },
  getTaskCategoriesWithPeriod: (periodId: number) => {
    return api
      .get("api/task-categories-with-period/" + periodId)
      .then((response) => response.data);
  },
  getCompanyReport: (periodId: number) => {
    return api
      .get("api/company-report/" + periodId)
      .then((response) => response.data);
  },
  getAllCities: () => {
    return api.get("api/cities/").then((response) => response.data);
  },
  getDistrictsOfCity: (id: number) => {
    return api
      .get("/api/cities/" + id + "/districts")
      .then((response) => response.data);
  },
  getAllPortfolios: () => {
    return api.get("api/portfolios/").then((response) => response.data);
  },
  getCompaniesHerdReport: () => {
    return api
      .get("api/companies-herd-report")
      .then((response) => response.data);
  },
  getTaskCategoryWithFilter: (companyId: number, periodId: number) => {
    return api
      .get("api/task-categories-with-filter/" + companyId + "/" + periodId + "")
      .then((response) => response.data);
  },
  getCompanyTasksWithPeriod: (companyId: number, periodId: number) => {
    return api
      .get("/api/company/" + companyId + "/period/" + periodId + "/tasks")
      .then((response) => response.data);
  },
};

export const taskExtendedApi = {
  getWaitForApproveTasks: () => {
    return api.get("/api/wait-for-approved-tasks").then((response) => {
      return response.data;
    });
  },
  taskEvaluation: (id: number, evaluationFields: any) => {
    return api
      .post("/api/task-evaluation/" + id.toString(), evaluationFields)
      .then((response) => response.data);
  },
};

export const generatedRecipeCrudApi = getCrudApi("generated-recipes");
export const generatedRecipeExtendedApi = {
  generateBackRecipe: (generatedRecipeId: number) => {
    return api
      .post("api/generate-back-recipe", {
        generatedRecipeId: generatedRecipeId,
      })
      .then((response: any) => {
        return response.data;
      });
  },
};

export const recipeCrudApi = getCrudApi("recipes");
export const recipeCrudExtendedApi = {
  generateRecipe: (values: any) => {
    return api.post("api/generate-recipe", values).then((response: any) => {
      return response.data;
    });
  },
};

export const materialCrudApi = getCrudApi("materials");
export const materialCrudExtendedApi = {
  addMaterialStock: (values: any) => {
    return api.post("api/add-material-stock", values).then((response: any) => {
      return response.data;
    });
  },
};

export const orderCrudApi = getCrudApi("orders");
export const orderExtendedApi = {
  deliverOrder: (orderId: number) => {
    return api
      .post("api/deliver-order", { orderId: orderId })
      .then((response: any) => {
        return response.data;
      });
  },
  cancelDeliverOrder: (orderId: number) => {
    return api
      .post("api/cancel-deliver-order", { orderId: orderId })
      .then((response: any) => {
        return response.data;
      });
  },
};

export const notificationCrudApi = getCrudApi("notifications");
export const notificationCrudExtendedApi = {
  create: (entity: any, nonCrud: boolean) => {
    let requestUrl = "api/notifications";
    if (nonCrud) requestUrl += "?nonCrud=true";

    return api.post(requestUrl, entity).then((response: any) => response.data);
  },
  edit: (id: number, entity: any, nonCrud: boolean) => {
    let requestUrl = "api/notifications/" + id;
    if (nonCrud) requestUrl += "?nonCrud=true";

    return api.put(requestUrl, entity).then((response: any) => response.data);
  },
  creates: (entity: any) => {
    return api
      .post("api/create-notifications", entity)
      .then((response: any) => response.data);
  },
  getAllTodayNotifications: () => {
    return api
      .get("api/all-today-notifications")
      .then((response: any) => response.data);
  },
  getOwnNotifications: () => {
    return api
      .get("api/own-notifications")
      .then((response: any) => response.data);
  },
  markAsRead: (id: any) => {
    return api
      .put("api/mark-as-read/" + id)
      .then((response: any) => response.data);
  },
};

export const userCrudExtendedApi = {
  renewPassword: (oldPassword: string, newPassword: string) => {
    return api
      .post("api/renew-password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      .then((response) => {
        return response.data;
      });
  },
  getAllRoles: () => {
    return api.get("api/roles").then((response) => {
      return response.data;
    });
  },
  currentUser: () => {
    return api.get("api/current-user").then((response) => {
      return response.data;
    });
  },
};

export const periodCrudApi = getCrudApi("periods");
export const periodCrudExtendedApi = {
  getCurrentPeriod: () => {
    return api.get("/api/current-period").then((response) => {
      return response.data;
    });
  },

  createWorkSheet: (periodId: number) => {
    return api.post("/api/create-work-sheet/" + periodId).then((response) => {
      return response.data;
    });
  },
};

export const usageDocumentApi = {
  get: () => {
    return api.get("api/usage-documents/1").then((response: any) => {
      return response.data;
    });
  },
  put: (content: string) => {
    return api
      .put("api/usage-documents/1", { content: content })
      .then((response: any) => {
        return response.data;
      });
  },
};

export const otherExtendedApi = {
  getCurrencies: () => {
    return api.get("/api/currency").then((response) => {
      return response.data;
    });
  },
};
