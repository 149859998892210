import { Form, InputNumber, Modal, Row, Select, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  materialCrudApi,
  materialCrudExtendedApi,
  otherExtendedApi,
} from "../client/EntityApi";
import {
  getMaterialCPColumns,
  getMaterialCPFormItems,
} from "../components/crud/MaterialCPComponents";
import { otherApi } from "../core/client/BaseEntityApi";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { mathRound } from "../core/helper/UtilHelper";
import { Rules } from "../core/Rules";
import AddStockIcon from "../Icons/AddStockIcon";

const MaterialCrudPage = () => {
  const { Title } = Typography;
  const [stockForm] = Form.useForm();
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [addStockModalIsOpen, setAddStockModalIsOpen] = useState(false);
  const [addStockLoading, setAddStockLoading] = useState(false);
  const [materials, setMaterials] = useState<any[]>([]);
  const [mappedMaterials, setMappedMaterials] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState([]);
  const [summaryStockPrice, setSummaryStockPrice] = useState<number>(0);
  const [currencyRates, setCurrencyRates] = useState({
    dolar: 1,
    euro: 1,
    tl: 1,
  });

  useEffect(() => {
    otherApi.constants().then((response: any) => {
      setCurrencies(response["currencies"]);
    });

    updateCurrentRates();
    const currencyInterval = setInterval(updateCurrentRates, 15000);

    return () => clearInterval(currencyInterval);
  }, []);

  useEffect(() => {
    let summaryStockPrice = 0;
    materials.forEach((material: any) => {
      const currencyRate = material.currency as "dolar" | "euro" | "tl";
      summaryStockPrice +=
        material.unit * material.quantity * currencyRates[currencyRate] ?? 1;
    });
    setSummaryStockPrice(mathRound(summaryStockPrice));
  }, [currencyRates, materials]);

  const updateCurrentRates = () => {
    otherExtendedApi.getCurrencies().then((response) => {
      setCurrencyRates({ dolar: response.dolar, euro: response.euro, tl: 1 });
    });
  };

  const onCancel = () => {
    stockForm.resetFields();
    setAddStockModalIsOpen(false);
    setAddStockLoading(false);
  };

  return (
    <>
      <Row justify="center">
        <Title level={4}>Toplam Stok Maliyeti : {summaryStockPrice} TL</Title>
      </Row>
      <CrudTable
        ref={crudPageRef}
        entityLabel="Hammadde"
        api={materialCrudApi}
        searchKeys={["name"]}
        columns={getMaterialCPColumns(currencyRates)}
        addFormItems={getMaterialCPFormItems(currencies)}
        editFormItems={getMaterialCPFormItems(currencies)}
        deleteModalMessage="Bu hammaddeyi sildiğinizde hammadeyi içeren reçetelerdende kaldırılacaktır. Silmek istiyor musunuz ?"
        extraTitleProcess={() => {
          return (
            <CrudPageProcessButton
              tooltipText="Stok Ekle"
              icon={<AddStockIcon />}
              onClick={() => setAddStockModalIsOpen(true)}
            />
          );
        }}
        afterRefreshOperation={(data) => {
          const mappedMaterials = data.map((material) => {
            return {
              label: material.name,
              key: material.name,
              value: material.id,
            };
          });
          setMappedMaterials(mappedMaterials);
          setMaterials(data);
        }}
      />
      <Modal
        maskClosable={false}
        forceRender
        visible={addStockModalIsOpen}
        title={"Stok Ekle"}
        onOk={() => {
          stockForm.validateFields().then((values) => {
            setAddStockLoading(true);
            materialCrudExtendedApi
              .addMaterialStock(values)
              .then((response) => {
                crudPageRef.current?.refreshData();
                toast.success(response.message);
              })
              .finally(() => onCancel());
          });
        }}
        onCancel={onCancel}
        okText="Ekle"
        cancelText="Vazgeç"
        confirmLoading={addStockLoading}
      >
        <Form form={stockForm} autoComplete="off" layout="vertical">
          <Form.Item
            label="Hammadde Seç"
            name="materialId"
            rules={[Rules.required]}
          >
            <Select
              showSearch
              options={mappedMaterials}
              filterOption={(input, option) =>
                (option?.label as string)
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Form.Item
            label="Miktar"
            name="quantity"
            initialValue={"0"}
            rules={[Rules.required]}
          >
            <InputNumber
              min={0}
              precision={3}
              decimalSeparator=","
              stringMode
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MaterialCrudPage;
