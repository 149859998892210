import { Form, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import PeriodSelect from "../components/PeriodSelect";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudPageTitleRow from "../core/component/crud/CrudPageTitleRow";
import LargeTag from "../core/component/LargeTag";
import { EditOutlined, EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import { companyExtendedApi, primPoolCrudApi } from "../client/EntityApi";
import { getPerformanceReportCPColumns } from "../components/crud/PerformanceReportCPComponents";
import { Rules } from "../core/Rules";
import Upload from "../core/component/Upload";
import { AuthHelper } from "../core/helper/AuthHelper";

const PerformanceReportCrudPage = () => {
  const [updatePrimPoolForm] = Form.useForm();

  const [selectedPeriodId, setSelectedPeriodId] = useState<number>();
  const [primPool, setPrimPool] = useState<any>();
  const [workerReports, setWorkerReports] = useState<any[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [workList, setWorkList] = useState<any[]>([]);
  const [workListDialogIsOpen, setWorkListDialogIsOpen] =
    useState<boolean>(false);
  const [primPoolEditDialogIsOpen, setPrimPoolEditDialogIsOpen] =
    useState<boolean>(false);
  const [primPoolEditLoading, setPrimPoolEditLoading] =
    useState<boolean>(false);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriodId]);

  const refreshData = () => {
    if (selectedPeriodId) {
      setDataLoading(true);
      companyExtendedApi
        .getUserReports(selectedPeriodId)
        .then((response) => {
          setPrimPool(response.primPool);
          setWorkerReports(response.workerReports);
        })
        .finally(() => setDataLoading(false));
    }
  };

  const workListOnCancel = () => {
    setWorkList([]);
    setWorkListDialogIsOpen(false);
  };

  const primPoolOnCancel = () => {
    updatePrimPoolForm.resetFields();
    setPrimPoolEditDialogIsOpen(false);
    setPrimPoolEditLoading(false);
  };

  return (
    <>
      <Table
        rowKey="workerId"
        loading={dataLoading}
        dataSource={workerReports}
        scroll={{ x: "100vw" }}
        style={{ padding: "10px" }}
        columns={getPerformanceReportCPColumns(
          setWorkList,
          setWorkListDialogIsOpen,
          refreshData
        )}
        title={() => (
          <CrudPageTitleRow
            firstCol={<b>Performans Raporu</b>}
            secondCol={
              <>
                <LargeTag text={"Prim Havuzu : " + primPool?.prim} />
                <CrudPageProcessButton
                  tooltipText="Prim Oranlarını Görüntüle"
                  disabled={!Boolean(primPool?.media?.uid)}
                  icon={<EyeOutlined />}
                  onClick={() =>
                    window.open(
                      primPool?.media?.uid
                        ? process.env.REACT_APP_BASE_URL + primPool?.media?.uid
                        : ""
                    )
                  }
                />
                {AuthHelper.checkVisibility("Prim Havuzunu Düzenle") && (
                  <CrudPageProcessButton
                    tooltipText="Havuzu Düzenle"
                    disabled={!Boolean(primPool)}
                    icon={<EditOutlined />}
                    onClick={() => {
                      updatePrimPoolForm.setFieldsValue({
                        prim: primPool?.prim,
                        media: primPool?.media,
                      });
                      setPrimPoolEditDialogIsOpen(true);
                    }}
                  />
                )}
              </>
            }
            thirdCol={
              <>
                <PeriodSelect
                  style={{ width: "50%", marginRight: "1em" }}
                  selectedPeriodId={selectedPeriodId}
                  setSelectedPeriodId={setSelectedPeriodId}
                />
                <CrudPageProcessButton
                  tooltipText={"Yeniden Yükle"}
                  icon={<ReloadOutlined />}
                  onClick={() => refreshData()}
                />
              </>
            }
          />
        )}
      />
      <Modal
        title="İş Listesi"
        visible={workListDialogIsOpen}
        okButtonProps={{ hidden: true }}
        okText="Düzenle"
        cancelText="Vazgeç"
        onCancel={workListOnCancel}
      >
        {workList.map((item, index) => {
          return (
            <LargeTag
              key={index}
              color=""
              text={item}
              tagStyle={{ width: "100%", margin: "2px" }}
            />
          );
        })}
      </Modal>
      <Modal
        title="Havuzu Düzenle"
        visible={primPoolEditDialogIsOpen}
        okText="Düzenle"
        cancelText="Vazgeç"
        onOk={() => {
          updatePrimPoolForm.validateFields().then((values) => {
            if (primPool) {
              setPrimPoolEditLoading(true);
              values.media = values.media ?? null;
              primPoolCrudApi
                .edit(primPool.id, values)
                .then(() => refreshData())
                .finally(() => primPoolOnCancel());
            }
          });
        }}
        onCancel={primPoolOnCancel}
        confirmLoading={primPoolEditLoading}
      >
        <Form form={updatePrimPoolForm} autoComplete="off" layout="vertical">
          <Form.Item label="Prim" name="prim" rules={[Rules.required]}>
            <Input />
          </Form.Item>
          <Form.Item name="media">
            <Upload />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PerformanceReportCrudPage;
