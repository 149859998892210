import { Button, Divider, Form, Input, InputNumber, Select, Space } from "antd";
import { Rules } from "../../core/Rules";
import { PlusOutlined } from "@ant-design/icons";
import DatePick from "../../core/component/DatePick";
import { getDateString } from "../../core/helper/UtilHelper";
import dayjs from "dayjs";

export const getConsumptionCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ürün",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Verilen Tarih",
      dataIndex: "date",
      key: "date",
      render: (value: any) => getDateString(value),
    },
    {
      title: "Stok",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Önerilen Miktar",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Kullanım Dozu",
      dataIndex: "dose",
      key: "dose",
    },
    {
      title: "Kalan Miktar",
      key: "remainderQuantity",
      render: (_value: any, row: any) => {
        const now = dayjs();
        const daily = row.quantity * row.dose;
        const usedDay = now.diff(row.date, "day");
        const usedQuantity = daily * usedDay;
        const remainderQuantity = row.stock - usedQuantity;
        return (remainderQuantity > 0 ? remainderQuantity : 0).toFixed(2);
      },
    },
    {
      title: "Kalan Gün Sayısı",
      key: "remainderDay",
      render: (_value: any, row: any) => {
        const now = dayjs();
        const daily = row.quantity * row.dose;
        const usedDay = now.diff(row.date, "day");
        const usedQuantity = daily * usedDay;
        const remainderQuantity = row.stock - usedQuantity;
        return (remainderQuantity / daily).toFixed(2);
      },
    },
  ];
};

export const getConsumptionCrudPageFormItems = (
  id: any,
  products: any[],
  addProduct: () => void,
  addProductName: string,
  setAddProductName: (name: string) => void,
  deleteProduct: (id: number) => void
) => {
  const { Option } = Select;
  return (
    <>
      <Form.Item label="Ürün Adı" name="productName" rules={[Rules.required]}>
        <Select
          showSearch
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input
                  placeholder="Yeni Ürün Giriniz"
                  value={addProductName}
                  onChange={(event) => setAddProductName(event.target.value)}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addProduct}
                >
                  Ürün Ekle
                </Button>
              </Space>
            </>
          )}
        >
          {products.map((item, index) => {
            return (
              <Option value={item.value} label={item.label} key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{item.label}</div>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteProduct(item.id);
                    }}
                    danger
                    size="small"
                  >
                    Sil
                  </Button>
                </div>
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Verilen Tarih" name="date" rules={[Rules.required]}>
        <DatePick />
      </Form.Item>
      <Form.Item
        label="Stok"
        name="stock"
        initialValue="0"
        rules={[Rules.required]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
      <Form.Item
        label="Önerilen Miktar"
        name="quantity"
        initialValue="0"
        rules={[Rules.required]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
      <Form.Item
        label="Kullanım Dozu"
        name="dose"
        initialValue={0}
        rules={[Rules.required]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        hidden
        name="company"
        rules={[Rules.required]}
        initialValue={"api/companies/" + id}
      >
        <Input hidden />
      </Form.Item>
    </>
  );
};
